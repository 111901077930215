.plan-progress {
  width: 100%;
  display: block;
  height: 10px;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;

  .progress-completed {
    height: 100%;
    display: block;
    background: #FF9D00;
    border-radius: 2px;
  }

  &.progress-incomplete .progress-completed {
    background: $color-tomato;
  }
}

.course-accordion {
  .text-date {
    font-size: 10px;
    color: #a0a0a0;
  }
}
