@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'utils/variables';

@import "~bootstrap/scss/bootstrap.scss";

@import 'forms/input-group';

@import 'common/type';
@import 'common/button';
@import 'common/classes';
@import 'common/tables';
@import 'common/fields';

@import 'layouts/sidebar';
@import 'layouts/bar-chart';
@import 'layouts/common';
@import 'layouts/loading';
@import 'layouts/modal';
@import 'layouts/accordion';
@import 'layouts/notification';
@import 'layouts/calendar';
@import 'layouts/dropdown';

@import 'pages/auth-pages';
@import 'pages/users-pages';
@import 'pages/users-pages';
@import 'pages/user-progress';
@import 'pages/course-view';
@import 'pages/pool-create';
@import 'pages/dash-page';
@import 'pages/user_view';
@import 'pages/pool-view-page';
@import 'pages/pool-selection';
@import 'pages/player_stats';


body {
  color: #242836;
  font-size: $font-size-main;
  // line-height: $line-height-main;
  min-width: 1224px;
}

@media (max-width: 1224px) {
  body {
    min-width: auto;
  }
}

main {
  background: #F7F7F7;
  margin-left: 204px;
  min-width: 800px;
  z-index: 1;
  position: relative;
}

img {
  max-width: 100%;
}

a.link {
  color: #FF9D00;
  font-weight: 500;
  text-decoration: none;
}

.opc-3 {
  opacity: .3;
}

.opc-2 {
  opacity: .2;
}

.opc-full {
  opacity: 1;
}

.opc-0 {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.m-back {
  margin-right: -15px;
  margin-left: -15px;
}

.section-header {
  margin-bottom: 20px;
  h2, h3 {
    margin: 0;
    color: #242836;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 17px;
  }

  a {
    text-decoration: none;
    font-size: 12px;
    color: #FF9D00;
  }
}

.breadcrumbs {
  font-size: 14px;
  color: #202020;
  a {
    color: #202020;
    text-decoration: none;
  }
  span:last-child {
    color: #4B4B4B;
    font-weight: bold;
  }
}

.blue-box {
  position: relative;
  min-height: 45px;
  // background: #eff4fb;
  // border-radius: 5px;
  padding: 15px;

  border: 1px solid #C2C2C2;
  border-radius: 4px;

  p {
    font-weight: bold;
    color: #242836;
    font-size: 16px;
  }

  .info {
    font-size: 16px;
    color: #242836;
    font-weight: 300;
  }

  input, textarea, select {
    height: 36px;
    border: none;
    background: #ffffff;
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
    border-radius: 5px;
  }

  textarea {
    min-height: 120px;
    resize: none;
  }

  .edit-btn {
    position: absolute;
    right: 20px;
  }
}

.more-btn {
  display: block;
  height: 35px;
  min-width: 55px;
  background: #fff;
  color: #222222;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  text-decoration: none;
  border: none;
  padding: 0 4px;
  &:hover {
    text-decoration: none;
    color: #222222;
  }
  svg {
    fill: #FF9D00;
    width: 7px;
  }
  &.link-color {
    color: #FF9D00;
  }
  &.small {
    width: initial;
    height: 28px;
    font-size: 10px;
    padding: 2px;
    line-height: 25px;
  }
  &.danger {
    color: #dc3545;
    border: 1px solid;
    svg {
      fill: #dc3545;
    }
    &:focus {
      outline: none;
    }
  }
}

.view-btn {
  height: 38px;
  width: 38px;
  background: #F7F7F7;
  border-radius: 4px;
  border: 1px solid #C2C2C2;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.link-color {
  color: #FF9D00;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  li {
    margin: 0 2.5px;
    a {
      background: #fff;
      font-size: 12px;
      text-decoration: none;
      cursor: pointer;
      color: #202020;
      width: 21px;
      height: 21px;
      line-height: 19px;
      border-radius: 4px;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      border: .5px solid #C2C2C2;
      font-weight: 300;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &.active a {
      border-color: #FF9D00;
      background: #FF9D00;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  .previous, .next {
    display: none;
  }
  &.show-arrow {
    .previous, .next {
      display: block;
      a {
        background: none;
      }
      &.disabled {
        a {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.avatar-container,
.user-avatar {
  width: 119px;
  height: 119px;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  position: relative;
  border: 0.5px solid #e0e0e0;

  label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    background: rgba(0, 40, 255, 0.4);
    display: none !important;

    span {
      line-height: 20px;
      color: #fff;
      margin-bottom: 15px;
    }
  }

  &:hover label {
    display: flex !important;
  }

}

.modal-default {
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eff4fb;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 25px 1px;
}

.subscription-modal {
  width: 350px;
}

.button-times {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  right: 4px;
  border: none;
  background: #eff4fb;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #242836;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg)
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg)
  }

  &.dark {
    background: transparent;
    &::before,
    &::after {
      background-color: #eff4fb;
    }
  }
}

.no-radius {
  border-radius: 0 !important;
}

.mt--20 {
  margin-top: -20px;
}

.weight-600 {
  font-weight: 600;
}

.w-100p {
  width: 100px;
}

.scroll-height {
  max-height: 120px;
  overflow-y: auto;
}

.certificate-page {
  background: #eff4fb;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  position: relative;
  font-size: 14px;

  span {
    &:not(:first-child) {
      margin-right: 3px;
    }
  }

  &__completed:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.rating > span:hover:before,
.rating > span.active:before,
.rating > span:hover ~ span:before,
.rating > span.active ~ span:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span:not(.active):hover:before,
.rating > span:not(.active):hover ~ span:before{
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span.active:before,
.rating > span.active ~ span:before {
  color: #FF9D00;
  text-shadow: 0 0 1px;
}

.rating > span:hover:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.certificate-item {
  position: relative;
  width: 250px;
  .hover-item {
    display: none;
    position: absolute;
    top: 13px;
    left: 16px;
    right: 16px;
    bottom: 18px;
    border-radius: 7px;
    color: white;
    background: rgba(0, 0, 0, 0.44);
    .buttons-area {
      background: #fff;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      button, a {
        white-space: nowrap;
        color: #FF9D00;
      }
    }
  }
  &:hover {
    .hover-item {
      display: flex;
    }
  }
}

.has-header {
  padding-top: 99px !important;
}

.text-elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.course-users-modal {
  min-width: 900px;
  min-height: 500px;
  .users-list {
    max-height: 500px;
    overflow: auto;
    width: 100%;
  }
  .content {
    overflow: auto;
  }
}

.text-underline {
  text-decoration: underline;
}

.fz-16 {
  font-size: 16px;
}

.min-100 {
  min-width: 100px;
}

.min-50 {
  min-width: 50px;
}

.min-120 {
  min-width: 120px;
}

.password-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: rgba(226, 226, 226, 0.8);

  .modal-content {
    margin-top: 10vh;
    padding: 30px 50px;
    width: unset;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    margin: -30px -50px 0;
    padding-bottom: 0;
    border: none;
  }

  .modal-close {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 20px;
      transform-origin: center;
      position: absolute;
      top: 50%;
      left: 50%;
      background: #FF9D00;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.upload-file-form {
  label {
    background: #fff;
    border: 1px dashed #707070;
    border-radius: 4px;
    width: 333px;
    height: 200px;
    border-radius: 5px;
    padding: 0 10px 0 15px;
    margin: 0;
    color: #FF9D00 !important;
    font-weight: normal;
    cursor: pointer;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
  }
  span {
    color: #FF9D00;
    font-size: 18px;
  }
}

.selected-blue {
  color: #FF9D00;
}

.report-success-status {
  h4 {
    font-size: 20px;
    color: #242836;
  }
  p {
    font-size: 21px;
    color: #777777;
    line-height: 33px;
    font-weight: normal;
    font-weight: 200;
  }

  &.error {
    h4 {
      color: #EB4D4D;
    }

    button.btn.btn-blue {
      background: #E88989 !important;
      color: #fff !important;
    }
  }

}

.editor-toolbar.fullscreen {
  z-index: 10000 !important;
}

.CodeMirror.CodeMirror-fullscreen {
  z-index: 10000 !important;
}

.filter-drop-down {
  .app-dropdown-header {
    color: #202020;
    font-size: 14px;
    margin-right: 0 !important;
    span {
      margin-right: 15px !important;
    }
    &:before,&:after {
      width: 8px;
      height: 8px;
    }
    &:before {
      top: 3px;
    }
    &:after {
      top: 5px;
    }
  }
  &.active {
    .app-dropdown-header {
      &:before {
        top: 12px !important;
      }
      &:after {
        top: 10px !important;
      }
    }
  }
  &.selected {
    .app-dropdown-header {
      opacity: 1;
    }
    &:after {
      position: absolute;
      content: '';
      height: 3px;
      background: #2aa7cc;
      left: 0;
      width: 100%;
      bottom: -18px;
      border-radius: 1.5px;
    }
  }
}


.drag-item {
  .action-buttons.has-remove {
    margin-right: -65px;
  }
  .btn {
    width: 50px;
    height: 50px;
    padding: 0;
    &.btn-drag, &.btn-check {
      background: #EFF3FC;
    }
    &.btn-remove {
      color: #fff;
      background: #E88989;
    }
  }
  &.drag-is-lesson-answer, &.drag-is-course-feedback {
    .action-buttons {
      .btn-drag {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    > li {
      height: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.drag-is-lesson-answer {
    .action-buttons {
      margin-right: -133px !important;
    }
  }
  &.drag-is-course-feedback {
    .action-buttons {
      margin-right: -59px !important;
    }
  }
}

.react-confirm-alert-body {
  max-width: 314px;
  font-size: 14px;
  color: #242836 !important;
  padding: 20px 38px !important;
  letter-spacing: 0.28px !important;
  h1 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .react-confirm-alert-button-group {
    justify-content: flex-end;
    button {
      font-size: 12px;
      color: #FF9D00;
      background: transparent;
      font-weight: bold;
      padding: 0;
      margin-left: 25px;
      margin-right: 0;
    }
  }
}

.email-editor {
  .ck.ck-content.ck-editor__editable {
    min-height: 300px !important;
  }
}

.text-word-break {
  word-break: break-all;
}

.entity-image-upload {
  height: 104px;
  border: 1px dashed gray;
}

.fz-14 {
  font-size: 14px;
}

.back-button {
  margin-right: 10px;
  svg {
    height: 18px;
  }
}

.page-footer {
  background: #eff4fb;
}

.entity-block {
  border-radius: 5px;
  border: 1px solid #FF9D00;
  background: #EFF4FB;
  padding: 22px;
  margin-bottom: 20px;
  img {
    width: 66px;
    height: 66px;
    margin-right: 40px;
    background: #fff;
  }
  p {
    min-width: 120px;
    font-size: 13px;
    color: #FF9D00;
    margin: 0;
    font-weight: bold;
  }
  h4 {
    color: #FF9D00;
    margin: 0;
    font-weight: bold;
    &.name {
      font-size: 24px;
    }
    &.address {
      font-size: 16px;
    }
  }
}

.plan-info-box {
  background: #eff4fb;
  padding: 25px 15px;
  border-radius: 5px;
  color: #242836;
  img {
    width: 100%;
  }
  p {
    font-size: 17px;
    font-weight: 500;
  }
  span {
    font-size: 13px;
    font-weight: 100;
  }
}

.subs-view-page {
  p.text-secondary {
    font-size: 17px;
  }
  .text-desc {
    font-size: 14px;
    color: #222222;
    font-weight: 300;
  }
}

.col-switch-btn {
  border-radius: 4px;
  border: 0.5px solid #d7d7d7;
}

.list-remove-btn {
  height: 28px;
  min-width: 30px;
  padding: 0;
  text-align: center;
}

.dicom-info-block {
  border: 1px solid #eff3fc;
  .card-header {
    background: #eff4fb;
    border-color: #eff3fc !important;
  }
  span {
    font-weight: 500;
  }
  div.col-6 {
    margin-bottom: 4px;
  }
}

.multi-select {
  width: 100%;
  > div {
    border: none;
  }
}

.opc-5 {
  opacity: .5;
}

.email-edit-info {
  font-size: 12px;
  color: #222222;
  letter-spacing: 0.24px;
  p {
    margin-bottom: 18px;
  }
  p.info-title {
    font-size: 14px;
    color: #FF9D00;
    font-weight: bold;
    margin-bottom: 8px;
  }
  span {
    color: #FF9D00;
    &.bold {
      font-weight: bold;
    }
  }
}

.certificate-form {
  margin: 30px 0;
  width: 640px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  .certificate-content {
    padding: 20px 105px 40px;
    font-size: 10px;
    color: #222222;
  }

  .certificate-logo-area {
    padding: 5px 30px 15px;
  }
}

.certificate-btn-area {
  width: 640px;
  float: right;
}

.certificate-item {
  position: relative;
  width: 226px;
  height: 280px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin: 20px;
  .info {
    position: relative;
    z-index: 2;
    height: 110px;
    padding: 18px 18px 15px 18px;
    color: #FF9D00;
    .name {
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
    }
    i {
      font-style: initial;
      color: #D3D4D6;
    }
    span {
      font-size: 12px;
    }
  }
  .hover-block {
    z-index: 1;
    border-radius: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#181818, 0.6);
  }
  &:hover {
    .hover-block {
      display: block;
    }
    .info {
      color: #fff;
      i {
        color: #fff;
      }
    }
  }
}

.multi-select {
  .multi-select-pre__multi-value {
    background: #EFF4FB;
    border-radius: 5px;
    .multi-select-pre__multi-value__label {
      color:#FF9D00;
      font-weight: normal;
    }
    .multi-select-pre__multi-value__remove {
      svg {
        width: 10px;
        height: 10px;
        path: {
          fill: #FF9D00;
        }
      }
      &:hover {
        background: #e1ecf9;
        color: #FF9D00;
      }
    }
  }
}

.multi-select-pre__placeholder {
  font-weight: normal;
  color: #dddddd !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.icon-indicator {
  width: 38px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #C2C2C2;
  text-align: center;
  line-height: 37px;
  img {
    width: 22px;
  }
}

.games-score-block {
  h4 {
    line-height: 24px;
    font-size: 20px;
    color: #6E6E6E;
    margin-bottom: 16px;
  }

  .game-block {
    position: relative;
    width: fit-content;
    > div.box {
      background: #fff;
      border-radius: 4px;
      padding: 5px 13px;
      height: 60px;

      &.time-block {
        min-width: 78px;
        font-size: 20px;
        color: #6E6E6E;
        line-height: 24px;
        span {
          line-height: 13px;
          font-size: 10px;
        }
      }

      &.edit-block {
        text-align: center;
        width: 70px;
        padding: 0;
      }

      &.game-info {
        width: 700px;
        p {
          font-size: 16px;
          font-weight: 600;
          color: #202020;
        }

        .team-score {
          font-size: 24px;
          font-weight: 600;
          color: #4B4B4B;
          letter-spacing: 0.48px;

          input {
            width: 80px;
            height: 40px;
            text-align: center;
            border: 2px solid #FF9D00;
            border-radius: 4px;
            font-size: 24px;
            color: #4B4B4B;
            margin: 0 5px;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        }
      }
    }
    .manual-update {
      position: absolute;
      left: 100%;
      border: 1px solid #EBEBEB;
      border-radius: 4px;
      width: 25px;
      height: 28px;
      text-align: center;
      margin-left: 5px;
      line-height: 28px;
      span {
        letter-spacing: 0.28px;
        color: #FF9D00;
        font-weight: bold;
        font-size: 14px;
      }
      .hover-block {
        display: none;
        text-align: left;
        border: 1px solid #EBEBEB;
        height: 28px;
        left: 28px;
        top: -1px;
        border-radius: 4px;
        background: #fff;
        position: absolute;
        line-height: initial;
        font-size: 9px;
        padding: 4px 5px;
        color: #4B4B4B;
        p {
          margin: 0;
          &.time {
            font-size: 6px;
            font-weight: bold;
          }
        }
      }
      &:hover {
        .hover-block {
          display: block;
        }
      }
    }
  }
}

.w-12 {
  width: 12px;
}

.knockout-page {
  .current-file {
    margin-bottom: 45px;
    font-size: 14px;
    font-weight: 600;
    color: #4B4B4B;
    a {
      color: #FF9D00;
      font-weight: normal;
    }
  }
  .file-upload-box {
    cursor: pointer;
    margin: 0 auto;
    width: 537px;
    height: 404px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px dashed #707070;
    border-radius: 4px;

    h5 {
      font-size: 18px;
      color:#F8B64C;
      margin-bottom: 20px;
      margin-top: 18px;
    }
    p {
      font-size: 12px;
      color: #C2C2C2;
      margin: 0;
    }
  }
  .file-upload-btn {
    text-align: right;
    margin-top: 100px;
    button {
      border-radius: 4px;
      min-width: 100px;
      font-size: 14px;
      border-color: #C2C2C2;
      background: #FF9D00;
      color: #FCFCFC;
      &:disabled {
        background: #EBEBEB;
        color: #202020;
      }
    }
  }
}

.win-pct {
  font-size: 24px;
}

.info-block {
  background: #fff;
  border-radius: 4px;
  padding: 12px 16px;
  .icon-area {
    margin-right: 16px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #C2C2C2;
    &.sub-title {
      font-size: 12px;
    }
  }
}

.border-black {
  border-color: #000 !important;
}

.border-right-none {
  border-right: none !important;
}

.team-select {
  p {
    padding: 5px 10px;
    padding-right: 18px;
    margin: 0;
  }
}

.pool-block-score {
  font-size: 24px;
  font-weight: 700;
}

.participants-block {
  .table-body {
    margin-left: -20px;
    margin-right: -20px;
  }
  .table-row {
    padding: 0 20px;
    &:hover {
      background: #FEF0DB;
    }
  }
}

.choose-team-placeholder {
  font-size: 10px;
  font-weight: 700;
  width: 120px;
  height: 28px;
  background: #FFF5DA;
  padding: 0 8px;
}

.player-prize-row {
  border-bottom: 1px solid #D9D9D9 !important;
  padding: 10px 16px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  background: #fff !important;
  margin-bottom: 0 !important;
  p.prize {
    font-weight: 700 !important;
    color: #FF9D00 !important;
  }
  &:nth-last-child(1) {
    border-bottom: none !important;
  }
}

.h-40 {
  height: 40px;
}

.border-dark {
  border: 1px solid #C2C2C2 !important;
}

.player-prize-container {
  overflow: auto;
  max-height: 197px;
}
