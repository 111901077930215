.app-dropdown {
  position: relative;

  .app-dropdown-header {
    cursor: pointer;
    padding-right: 20px;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      right: 7px;
      transform: rotate(45deg);
    }

    &::before {
      background: #fff;
      top: 2px;
      z-index: 2;
    }
    &::after {
      background: #FF9D00;
      top: 4px;
      z-index: 1;
    }
  }

  &.active .app-dropdown-header {
    &::before {
      top: 11px;
    }
    &::after {
      top: 9px;
    }
  }

  .app-dropdown-body {
    min-width: 200px;
    max-height: 345px;
    background: #fff;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 10px;
    padding: 15px;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  &.to-left .app-dropdown-body {
      left: unset;
      right: 0;
    }

  &.to-top .app-dropdown-body {
    top: unset;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    height: 100%;
    overflow: auto;

    li:not(.disabled):hover {
      background: #eff4fb;
    }
  }

  a {
    text-decoration: none;
  }
}

// Specific
.export-drop-down {
  padding: 0;

  ul {
    padding: 0;
  }

  .drop-item {
    padding: 10px 10px;
    white-space: nowrap !important;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 2px solid #eff4fb;
    }
  }
}
