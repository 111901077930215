.sheets-page {
  padding: 50px 0;

  .user-img-area {
    min-width: 120px;
    height: 120px;
    text-align: center;
  }

  .sheets-area {
    padding-left: 149px;
  }

  li div.font-weight-bold {
    color: #4B4B4B;
  }

  .notes-area {
    padding-left: 149px;

    textarea {
      min-height: 80px;
      &:disabled {
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border: 0.5px solid #C2C2C2;
        border-radius: 4px;
      }
    }
  }
}

.border-box {
  position: relative;
  border: 1px solid #C2C2C2;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  &.small-radius {
    border-radius: 4px;
  }

  &.active {
    border: 1px solid #FF9D00;
  }

  .border-toggle {
    padding: 0;
    position: absolute;
    right: 16px;
    top: 8px;
    transform: rotate(90deg);
    &.active {
      right: 19px;
      transform: rotate(270deg);
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &.drop-menu.closed {
    padding: 8px 20px !important;
    > div {
      line-height: 20px;
    }
  }
}

.card-box {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 4px 4px;
  background: #fff;

  .card-header {
    padding: 6px 20px;
    font-size: 18px;
    color: #FCFCFC;
    font-weight: bold;
    letter-spacing: 0.36px;
    background: #256E00;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px 4px 0px 0px;

    &.blue {
      background: #2AA6CC;
    }

    &.orange {
      background: #F8B64C;
    }

    &.red {
      background: #F86A4C;
    }
  }
}

.compare-block {
  font-size: 24px;
  color:#C2C2C2;
  font-weight: bold;
  letter-spacing: 0.48px;
  span {
    &:nth-child(1) {
      color: #F8B64C;
    }
    &:nth-child(2) {
      margin: 0 3px;
    }
    &:nth-last-child(1) {
      color: #F86A4C;
    }
  }
}

.profile-update-form {
  .input-group {
    min-width: inherit;
    border: none;
    input {
      padding: 7px 14px;
      height: 38px;
      border: 1px solid #C2C2C2;
      border-radius: 4px;
      font-size: 14px;
      color: #6E6E6E;
    }
  }
}
