nav.sidebar {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 204px;
  z-index: 10;
  color: #b5bef6;
  overflow: auto;
  min-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  .avatar {
    border-radius: 4px;
    margin-bottom: 10px !important;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
      object-position: top;
    }
  }

  .profile-info {
    span {
      color: #202020;
      font-size: 14px;
      font-weight: bold;
    }

    .log-out {
      font-size: 12px;
      color: #202020;
      font-weight: normal;
    }
  }

  .navigation {
    > ul {
      padding: 0;
      list-style-type: none;
      > li {
        padding: 0;
        margin: 0;
        a {
          padding: 12px 29px;
          font-size: 14px;
          text-decoration: none;
          color: #202020;
          &.active {
            font-weight: 600;
            color: #FF9D00;
            background: #FF9D001A;
          }

          .icon-div {
            width: 25px;
            margin-right: 15px;
          }
        }
      }
    }
  }
  .sub-menu {
    overflow: hidden;
    > li {
      > a {
        white-space: nowrap;
        font-size: 15px;
        color: #b5bef6 !important;
        text-overflow: ellipsis;
        &.active, &:hover {
          color: #fff !important;
        }
      }
    }

    &.users {
      list-style-type: square;
    }
  }
}
