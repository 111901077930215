.users-header {
  padding: 0 20px;
  height: 70px;
  -webkit-box-shadow: 5px 0px 7px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 5px 0px 7px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 5px 0px 7px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  z-index: 100;
  background: white;
  top: 0px;
  right: 0;
  left: 204px;
  width: auto;

  h3.header-title {
    font-size: 18px;
    color: #202020;
    font-weight: bold;
  }

  > div.d-flex {
    height: 100%;
    @extend .align-items-center;
  }

  .filters {
    height: 100%;
    margin-left: 28px;
    a {
      padding: 0 20px;
      margin: 0 !important;
      @extend .d-flex;
      @extend .align-items-center;
      height: 100%;
      color: #202020;
      font-size: 14px;
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: #FF9D00;
        font-weight: bold;
        background: #F7F7F7;
      }
    }
  }
}

.create-form-steps {
  z-index: 50;
  position: fixed;
  top: 70px;
  width: 100%;
  background: #f7f7f7;
  ul {
    padding: 0;
    margin: 0;
    li {
      position: relative;
      height: 40px;
      width: 140px;
      font-size: 12px;
      color: #FF9D00;
      background: #eaeaea;
      padding: 0 20px;
      &:before {
        content: "";
        position: absolute;
        right: -7px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 7px solid white;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        z-index: 10;
      }
      &:after {
        content: "";
        position: absolute;
        right: -6px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 7px solid #eaeaea;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        z-index: 10;
      }
      &.active {
        background: #FF9D00;
        color: #fff;
        &:before {
          right: -7px;
          border-left: 7px solid #FF9D00;
          z-index: 11;
        }
        &:after {
          right: -8px;
          border-left: 7px solid white;
        }
      }
      &.completed {
        background: #F8B64C;
        color: #fff;
        &:before {
          right: -7px;
          border-left: 7px solid #F8B64C;
          z-index: 11;
        }
        &:after {
          right: -8px;
          border-left: 7px solid white;
        }
      }
    }
  }
}

.creation-form-content {
  min-height: calc(100vh - 180px) !important;
}

.btn-invite {
  background: #FFFFFF;
  border: 0.5px solid #d7d7d7;
  border-radius: 4px;
  font-size: 14px;
  @extend .d-flex;
  @extend .align-items-center;
  a {
    text-decoration: none;
    font-size: 14px;
    color: #202020;
  }
  span {
    display: block;
    height: 10px;
    width: 10px;
    color: #FF9D00;
    border: 0.5px solid #d7d7d7;
    line-height: 8px;
    margin-right: 6px;
    font-size: 8px;
    margin-top: 2px;
    border-radius: 1px;
  }
}

.search-block {
  position: relative;
  border: 0.5px solid #d7d7d7;
  border-radius: 4px;
  input {
    width: 230px;
    height: 38px;
    border: none;
    padding: 0 30px 0 10px;
    outline: none;
    font-size: 14px;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 12px;
  }

  &.icon-in {
    input {
      width: 100%;
    }
    svg {
      position: absolute;
      right: 7px;
    }
  }
  &.is-dark {
    border: 1px solid #C2C2C2;
  }
}


.users-lists {
  ul {
    li {
      font-size: 12px;
      color: #4B4B4B;
      font-weight: bold;
      height: 61px;
      padding: 7px;
      border: 0.5px solid #d7d7d7;
      border-top: none;
      font-weight: 500;
      > div {
        &:first-child {
          padding-left: 0;
        }
        &:nth-last-child(1) {
          padding-right: 0;
        }
      }
      &.list-header {
        height: 30px;
        border: 0;
        background-repeat: 0;
        div {
          color: #6E6E6E !important;
          font-weight: 400;
          font-size: 12px;
        }
      }

      &.list-item {
        background: #fff;
      }

      &.h-not-set {
        height: inherit;
        min-height: 60px;
      }
      &.no-height {
        height: inherit;
      }
      &:nth-child(2) {
        border-top: 0.5px solid #d7d7d7;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:nth-last-child(1) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .user-image {
    width: 38px;
    height: 38px;
    border-radius: 4px;
    object-fit: cover;
    object-position: top;
    margin-right: 15px;
  }
  &.with-scroll {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    ul {
      li > div {
        padding: 7px;
      }
      li.list-item {
        padding: 0 7px;
        > div {
          @extend .d-flex;
          @extend .h-100;
          @extend .align-items-center;
          background: #eff4fb;
        }
      }
    }
  }
}

.user-creation-form {
  li {
    // @extend .flex-column;
    // background: $color-blue-light;
    // border-radius: 5px;
    color: #6E6E6E;
    // font-weight: bold;
    margin-bottom: 20px;
    list-style-type: none;
  }

  .form-field-group {
    > div {
      padding: 0;
      margin-bottom: 6px;
      label {
        padding-left: 10px;
        font-size: 14px;
        color: #6E6E6E;
        font-weight: 500;
      }
    }
    .text-danger {
      font-size: 10px;
      padding-left: 10px;
    }
    &.is-checkbox {
      @extend .d-flex;
      @extend .align-items-center;
      > div {
        margin-bottom: 0px;
      }
    }
  }

  span.result {
    font-weight: 300;
    padding: 0 13px;
  }

  .input-group {
    border: none;
    margin: 0 !important;
    &.has-error {
      input {
        border: 1px solid;
      }
    }
  }

  .input-group input, select, textarea {
    padding: 0 13px;
    height: 38px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    border: none;

    &::-webkit-input-placeholder {
      color: #ddd;
    }
  }

  textarea {
    resize: none;
    outline: none;
    width: 100%;
    height: 60px;
    padding-left: 13px !important;
    padding-top: 7px !important;
    &.is-small {
      height: 70px;
    }
    &.is-large {
      height: 130px;
    }
  }

  .input-with-button {
    position: relative;

    input {
      padding-right: 80px;
    }

    button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.subs-plan-area {
  background: #EFF4FB;
  border-radius: 5px;
  min-height: 82px;
  padding: 9px;
  font-size: 11px;
  margin: 6px 0 12px 0;
  max-height: 155px;
  overflow: scroll;
  .plan-box {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 6px 12px;
    margin-bottom: 5px;
  }
  .plan-name {
    color: #242836;
  }
  button {
    text-align: right;
    min-width: 90px;
    margin-left: 50px;
    font-size: 11px;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  .plan-dates {
    width: 150px;
    span {
      padding: 0 7px;
      &:nth-child(2){
        border-left: 2px solid #d3d4d7;
      }
    }
  }

  .plan-button-area {
    width: 200px;
  }

  form {
    margin-left: 10px;
    input {
      border: none;
      border-bottom: 1px solid;
      max-width: 80px;
      &:focus {
        outline: none;
        border-bottom: 1px solid #FF9D00;
      }
    }
    &.has-key {
      button {
        min-width: initial;
        margin-left: 5px;
      }
    }
  }
}

.create-info-message {
  letter-spacing: 0.28px;
  color: #202020;
  line-height: 24px;
  font-size: 14px;
}
