.table-custom {
  border-collapse: separate;
  border-spacing: 0 8px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;

  th, td {
    padding: 0;
    border: none !important;
    vertical-align: middle !important;

    &:first-child:not(:last-child) {
      &.col-index {
        text-align: center;
      }
    }

    &.cell-fixed {
      &__sm {
        max-width: 150px;
      }
      &__md {
        max-width: 170px;
      }
      &__lg {
        max-width: 200px;
      }
      &__xlg {
        max-width: 250px;
      }
      &__xxlg {
        max-width: 350px;
      }
    }

    &:not(:last-child) {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        background: #bac3d0;
        height: 40px;
      }
    }
  }

  th {
    padding: 5px 14px;

    &:not(:last-child)::after {
      height: 21px;
    }
  }

  td {
    background: #eff4fb;
    padding: 16px 14px;

    &:first-child:last-child {
      border-radius: 5px;
    }

    &:first-child:not(:last-child) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child:not(:first-child) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

}

.status-pointer {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;

  &__success {
    background-color: #6BCC71
  }
  &__warning {
    background-color: #FFE400
  }
  &__danger {
    background-color: #D53838
  }
}

.table-first-header {
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #4B4B4B;
    text-decoration: underline;
    letter-spacing: 0.4px;
  }

  .header-icons {
    width: 379px;
    margin-right: 128px;
  }
}

.table-eye-btn {
  width: 30px;
  height: 30px;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 0.5px solid #C2C2C2;
  border-radius: 4px;
  padding: 1px;
}

.sheet-view-table {
  .table-header {
    background: #256E00 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    height: 50px;
    color:#FCFCFC;
    font-size: 14px;
    letter-spacing: 0.28px;
    padding: 0 30px;
    h4 {
      margin: 0;
      font-size: 18px;
      letter-spacing: 0.36px;
    }
  }
  th {
    color: #202020;
    font-size: 10px;
    background: #C2C2C2;
    vertical-align: bottom;
  }

  td {
    font-size: 12px;
    font-weight: 600;
  }

  table, td {
    border: 1px solid #909090;
    border-collapse: collapse;
  }

  th, td {
    padding: 5px 15px;
  }
}
