.pool-view-page {
  .has-header {
    padding-top: 72px !important;
  }
  .pool-cover {
    position: relative;
    padding: 27px 50px 27px 70px;
    background: url('../../assets/pool.png');
    background-position-y: -30px;
    background-size: cover;
    height: 268px;
    color: #FCFCFC;

    h3 {
      letter-spacing: 0.72px;
      font-size: 36px;
      margin-bottom: 3px;
      font-weight: bold;
    }

    h4 {
      letter-spacing: 0.48px;
      font-size: 24px;
      margin-bottom: 32px;
      font-weight: bold;
    }

    button {
      width: 135px;
      height: 35px;
      margin-bottom: 10px;
      font-size: 14px;
      letter-spacing: 0.28px;
      color: #FCFCFC;
      border: 1px solid #6E6E6E;
      border-radius: 4px;

    }
    .btn.cover-btn {
      width: 38px;
      height: 38px;
      background: #F8B64C 0% 0% no-repeat padding-box;
      padding: 4px;
      margin-bottom: 15px;
      border-radius: 4px;
      &:hover {
        background: #FF9D00;
      }
    }

    .survival-pool-cover-title-img {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
      left: 0;
      img {
        height: 50px;
      }
    }
  }
  &.is-survival {
    .pool-cover {
      height: 337px;
      h3 {
        margin-top: 23px;
      }
      h4 {
        margin-bottom: 16px;
        margin-top: 10px;
      }
      div {
        z-index: 1;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        bottom: 0;
        width: 400px;
      }
    }
    button:not(.pool-view-btn) {
      height: 40px;
      width: 176px;
      font-size: 16px;
      font-weight: 700;
      color: #4B4B4B;
      border-width: 2px;
      &.btn-primary {
        border: none;
        background: #FF9D00;
        color: #fff;
      }
    }
    .btn.cover-btn {
      background: #FF9D00;
    }
  }
}

.pool-table {
  background: #EBEBEB;
  .p-table-header {
    height: 50px;
    font-size: 18px;
    letter-spacing: 0.36px;
    color: #FCFCFC;
    padding: 12px 30px;
    font-weight: 600;
  }
  &.is-green .p-table-header {
    background: #256E00;
  }

  &.is-brown .p-table-header {
    background: #381D13;
  }

  &.brown-right .p-table-header {
    border-right: 1px solid #F7F7F7;
  }

  .p-tabel-body {
    li {
      height: 30px;
      padding: 6px 30px;
      border-bottom: 1px solid #F7F7F7;
      border-right: 1px solid #F7F7F7;
      background: #EBEBEB 0% 0% no-repeat padding-box;
      color: #202020;
      font-size: 12px;
      > div:nth-child(1) {
        text-transform: capitalize;
        color: #4B4B4B;
        font-weight: 600;
      }
      &:nth-child(even) {
        background: #e4e4e4;
        border-right: none;
      }
    }
  }
}

.pool-survival-winners {
  box-shadow: 0px 3px 6px 0px #00000029;
  margin: 30px;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
  .win-header {
    color: #fff;
    height: 50px;
    background: #4B4B4B;
    font-size: 18px;
    font-weight: 500;
    padding: 13px 24px;
    span {
      font-weight: 700;
    }
    &.green {
      background: #256E00;
    }
  }
  .win-columns {
    & > div {
      padding: 16px;
      border-right: 1px solid #C1C1C1;
      text-align: center;
      &:nth-last-child(1) {
        border-right: none;
      }
    }
    span {
      font-weight: 700;
      font-size: 20px;
      color: #4B4B4B;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
}

.num-buttons {
  button {
    box-shadow: 0px 1px 2px 0px #00000026;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 16px;
    width: 37px;
    height: 37px;
    background: #fff;
    &.active {
      font-weight: 700;
      color: #fff;
      background: $primary;
    }
    &:focus {
      box-shadow: 0px 1px 2px 0px #00000026;
    }
  }
}

.pool-view-btn {
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  background: #FFF;
  margin-right: 16px;
}

.tiers-image {
  margin-bottom: 12px;
  position: relative;
  span {
    position: absolute;
    bottom: 9px;
    color: #FF9D00;
    text-align: center;
    font-family: Montserrat;
    font-size: 7.18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.144px;
    display: block;
    width: 100%;
  }
}

.see-stats-button {
  padding: 10px 32px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  border-radius: 4px;
  background: #FF9D00;
  &:hover {
    background: #FF9D00;
    color: #fff;
  }
}
