.hide {
  display: none;
}

.page-title {
  padding: 33px 21px 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)
}

.list-default {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    border-radius: 5px;
    background: #eff4fb;
    padding: 18px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}


.plan-progress-box {
  width: 100%;
  display: block;
  height: 9px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #FF9D00;
  border-radius: 11px;
  padding: 1px;

  .progress-completed {
    height: 100%;
    display: block;
    background: #FF9D00;
    height: 5px;
    border-radius: 11px;
  }
}

.semi-checked {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 1px;
    background: #FF9D00;
  }
}

.outline-blue {
  border: 1px solid #FF9D00;
}

.outline-transparent {
  border: 1px solid transparent;
}

.icons-list path {
  fill: #FF9D00
}

.status-explanation-list {
  list-style: none;

  li {
    position: relative;
    &::after {
      content: '';
      left: -16px;
      transform: translateY(-50%);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      width: 8px;
      height: 8px;
    }
    &:nth-child(1)::after {
      background: #569A58;
    }
    &:nth-child(2)::after {
      background: #699CD6;
    }
    &:nth-child(3)::after {
      background: #34609A;
    }
    &:nth-child(4)::after {
      background:rgb(17, 50, 228);
    }
    &:nth-child(5)::after {
      background:rgb(96, 119, 247);
    }
  }
}

.text-truncate-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.doughnut-chart {
  position: relative;
  z-index: 0;

  .center-text {
    position: absolute;
    color: #FF9D00;
    font-weight: 600;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
}
