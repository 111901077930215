.btn-blue {
  color: $color-blue !important;
  background-color: $color-blue-light !important;
}

.btn-gray {
  font-size: 14px;
  color: #202020;
  background: #EBEBEB;
  border-radius: 4px;
  border: 1px solid #C2C2C2;
  padding: 8px 30px;
}

.btn-outline-blue {
  color: $color-blue !important;
  border-color: $color-blue !important;
}

.btn-red {
  color: #fff;
  background-color: #E88989;
  &:hover {
    color: #fff;
  }
}

.btn-white {
  color: $color-blue !important;
  background-color: #fff;
}

.btn-primary {
  background-color: #F8B64C;
  color: #fff;
  border: none;

  &:hover {
    background-color: #FF9D00;
  }
}

.btn-rounded {
  border-radius: 30px;
}

.btn-truncate {
  @extend .text-truncate
}

.btn.btn-non-shadow {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn.save-btn {
  width: 38px;
  height: 38px;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 0.5px solid #C2C2C2;
  border-radius: 4px;
  padding: 1px;
}

.pass-btns {
  height: 24px;
  width: 24px;
  background: #F7F7F7;
  border-radius: 4px;
  line-height: 17px;
  margin-bottom: 2px;
  svg {
    width: 16px;
    path {
      fill: #FF9D00;
    }
  }
  &.active {
    background: #FF9D00;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.btn.btn-arrows {
  width: 38px;
  height: 38px;
  background: #fff;
  border: 0.5px solid #C2C2C2;
  border-radius: 4px;
  &:disabled {
    border-color: #C2C2C2;
    background: transparent;
    svg path {
      fill: #c2c2c2;
    }
  }
}

.btn-filter {
  height: 38px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  padding: 10px 16px;
  line-height: 18px;
  font-size: 14px;
  color: #FF9D00;
  &.active {
    background: #F8B64C 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}
