.text-blue,
.color-blue {
  color: $color-blue
}

.color-tomato {
  color: $color-tomato;
}

.text-lg {
  font-size: 17px;
}

.text-sm {
  font-size: 11px;
}

.white-space-pre {
  white-space: pre-line
}

.white-space-clip,
.text-clip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}

.text-semy-grey {
  color: #6E6E6E;
}

.fz-12,
.font-size-12 {
  font-size: 12px;
}

.fz-13,
.font-size-13 {
  font-size: 13px;
}

.no-white-space {
  white-space: nowrap;
}

.weight-100 {font-weight: 100;}
.weight-200 {font-weight: 200;}
.weight-300 {font-weight: 300;}
.weight-400 {font-weight: 400;}
.weight-500 {font-weight: 500;}
.weight-600 {font-weight: 600;}
.weight-700 {font-weight: 700;}
.weight-800 {font-weight: 800;}
.weight-900 {font-weight: 900;}

.text-large {
  font-size: 18px;
}

.text-gray {
  color: #6E6E6E;
}
