.pool-creation {
  h6 {
    font-size: 18px;
    color: #4B4B4B;
    font-weight: 600;
    letter-spacing: 0.36px;
    padding-left: 10px;
  }
  .episode-form {
    background: #EFF3FC;
    border-radius: 5px;
    margin: 9px 0;
    &.user-creation-form li {
      margin-bottom: 0;
      label {
        font-size: 13px;
      }
    }
  }
  .episode-block {
    p {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 17px;
      color: #FF9D00;
    }
  }
}

.upload-wizard-block {
  position: relative;
  padding: 72px 30px;
  border: 3px dashed #EFF3FC;
  font-size: 13px;
  color: #FF9D00;
  font-weight: bold;
  img {
    margin-bottom: 25px;
  }
  .success-uploaded {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.lesson-complete-step {
  h4 {
    margin-top: 40px;
    margin-bottom: 13px;
    font-size: 28px;
    color: #242836;
    font-weight: 700;
  }
  p {
    line-height: 33px;
    font-size: 21px;
    color: #777777;
    margin-bottom: 14px;
    font-weight: 200;
  }
  button {
    color: #fff;
    background-color: #FF9D00;
    &:hover {
      color: #fff;
    }
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-box.case-select-modal {
  width: 530px;
  min-height: 530px;
  padding: 40px 0;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 4px 0px 0px 4px;
  .m-header {
    text-align: center;
    h5 {
      font-size: 24px;
      color: #6E6E6E;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #777777;
      font-weight: 100;
    }
  }
  .case-count {
    padding: 10px 41px;
    font-size: 13px;
    margin: 0;
    color: #FF9D00;
    margin-bottom: 30px;
    font-weight: bold;
  }
}

.case-item, .author-item {
  background:#fff;
  padding: 14px 41px;
  border-bottom: 2px solid #e9e9eb;
  &:nth-last-child(1) {
    border-bottom: none;
  }

  p {
    font-size: 13px;
    color: #D3D4D6;
    font-weight: normal;
    b {
      font-weight: bold;
      color: #FF9D00;
    }
  }

  .case-item-title {
    color: #242836;
    font-size: 17px;
    font-weight: bold;
    span {
      font-size: 13px;
      color: #FF9D00;
    }
  }
  &.with-drag {
    border-bottom: 2px solid #e9e9eb;
  }
  &.author-item {
    padding: 10px 38px;
  }
  &.lesson-item {
    padding: 8px 35px 8px 15px;
  }
}

.author-item-comp {
  span {
    margin-top: 10px;
    color: #222222;
    font-size: 14px;
    font-weight: bold;
  }
  p {
    color: #777777;
    font-size: 11px;
  }
  img {
    border-radius: 100%;
    width: 106px;
    height: 106px;
    object-fit: cover;
    object-position: top;
  }

  .image-bordered {
    border: 1px solid rgba(#3c4aa3, .5);
    border-radius: 100%;
    padding: 14px;

    img {
      min-width: 160px;
      min-height: 160px;
      line-height: 160px;
      text-align: center;
    }
  }

  a {
    color: #FF9D00;
    font-size: 12px;
    text-decoration: none;
  }
}

.lesson-question-create, .course-question-create {
  .user-creation-form li {
    min-height: 50px;
  }
}

.add-new-items-btns {
  margin-top: 10px;
  button {
    height: 35px;
    font-size: 12px;
    font-weight: bold;
    width: 136px;
  }
}

.course-question-create {
  button.btn-remove {
    margin-right: -57px;
  }
}

h3.step-title {
  font-weight: bold;
  font-size: 20px;
  color: #242836;
  margin-bottom: 10px;
}

.course-authors-row {
  img {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    margin-right: 10px;
    display: block;
    text-align: center;
  }
  .image-container {
    &.no-image {
      width: 44px;
      height: 44px;
      border: 1px solid #e9e9eb;
      border-radius: 100%;
      margin-right: 10px;
      text-align: center;
      font-size: 10px;
      padding-top: 4px;
      img {
        display: none;
      }
      &:after {
        content: 'no image'
      }
    }
  }
  .author-name {
    h5 {
      color: #242836;
      font-size: 17px;
      span {
        font-size: 13px;
        color: #FF9D00;
        font-weight: bold;
      }
    }
    p {
      font-size: 13px;
      margin: 0;
      color: #D3D4D6;
      white-space: nowrap !important;
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.subs-course-row {
  .image-container {
    min-width: 66px;
    margin-right: 10px;
    max-width: 66px;
  }
  img {
    width: 100%;
  }
  .course-desc {
    font-size: 13px;
    color: #242836;
    white-space: nowrap;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.course-lessons-row {
  .image-container {
    width: 66px;
    margin-right: 10px;
  }
  img {
    width: 100%;
  }
  h5 {
    color: #242836;
    font-size: 17px;
    margin-bottom: 4px;
    span {
      font-size: 13px;
      color: #FF9D00;
      font-weight: bold;
    }
  }
  p.note {
    font-size: 13px;
    margin: 0;
    color: #242836;
    text-transform: uppercase;
    white-space: nowrap !important;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.course-pdf-step {
  p {
    margin-top: 30px;
    font-size: 13px;
    text-align: center;
    color: #242836;
    font-weight: bold;
  }
}


.cases-block {
  .case-block {
    cursor: pointer;
    min-width: 130px;
    height: 48px;
    padding: 5px;
    background: #EFF3FC;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 20px;

    svg {
      margin-right: 5px;
    }

    h4 {
      font-size: 14px;
      color: #181818;
      font-weight: 700;
    }
    p {
      font-size: 13px;
      color: #D3D4D6;
      b {
        font-weight: normal;
        color: #FF9D00;
      }
    }
    &.active {
      background: #FF9D00;
      h4, p b {
        color: #fff;
      }
      p {
        color: #B5BEF6;
      }
      svg path {
        fill: #fff;
      }
    }
  }
}

.lesson-heatmaps {
  .image-area {
    padding: 15px;
    height: 400px;
    background: #000;
    color: rgba($color: #fff, $alpha: .8)
  }
}


.financial-page {
  .header-block {
    p {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.48px;
      color: #4B4B4B;
    }
  }

  .white-block {
    background: #FCFCFC 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px 20px;
  }

  .block-title {
    min-width: 210px;
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #202020;
    font-weight: 600;
  }

  input {
    border: 1px solid #F8B64C;
    border-radius: 4px;
    height: 24px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 120px;
    max-width: 120px;
    font-size: 14px;
    &.is-small {
      width: 80px;
      max-width: 80;
    }
  }
}


.amount-switcher {
  .switch-btns {
    height: 24px;
    width: 45px;
    border: 1px solid #F8B64C;
    border-radius: 4px;
    background: #F7F7F7;
    button {
      border-radius: 0;
      width: 22px;
      text-align: center;
      color: #202020;
      padding: 0;
      font-size: 14px;
      &.active {
        color: #fff;
        background: #F8B64C;
      }
      &:active, &:focus {
        box-shadow: none;
      }
    }
    &.type-on-off {
      width: 72px;
      button {
        width: 36px;
      }
    }
  }

  .final-value {
    margin-left: 75px;
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #202020;
  }
}

.amount-toggle {
  .first-section {
    min-width: 180px;
  }

  .checkbox-area {
    width: 25px;
  }
}

.white-block-info {
  padding-left: 229px;
  margin-bottom: 5px;
  p {
    margin-right: 135px;
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #202020;
  }
}

.creation-buttons {
  button {
    height: 35px;
    min-width: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    border: 0.5px solid #C2C2C2;
    border-radius: 4px;
    letter-spacing: 0.28px;
    color: #202020;
    font-size: 14px;
    &.btn-next {
      background: #F8B64C 0% 0% no-repeat padding-box;
      color: #fff;
      border-color: #F8B64C;
    }
  }
}

.amount-calc-devide {
  span {
    display: block;
    text-align: right;
    letter-spacing: 0.28px;
    color: #202020;
    font-size: 14px;
    line-height: 18px;
    &.result-line {
      margin: 2px 0;
      height: 1px;
      background: #202020;
      width: 100%;
    }
  }

  .result {
    position: relative;
    .result-missing, .result-surplus {
      position: absolute;
      left: 100%;
    }
    &.wrong {
      color: #FF3333;
    }
    &.success {
      color: #2BD138;
    }
  }

  .result-missing {
    color: #FF3333;
  }
  .result-surplus {
    color: #2BD138
  }

  .top-part {
    position: relative;
    .devide-action {
      position: absolute;
      left: -10px;
      top: 17px;
      font-size: 18px;
    }
  }
}

.pool-survival-settings {
  padding: 32px;

  .settings-item {
    margin-bottom: 20px;
    label {
      min-width: 150px;
      margin-right: 10px;
    }
    p {
      line-height: 16px;
      color: #202020;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 12px;
      &.sub-desc {
        font-weight: 400;
        font-size: 12px;
        font-style: italic;
        margin-bottom: 0;
      }
    }
  }
}
