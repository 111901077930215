.pool-new-selection {
  padding-top: 130px;
}

.pool-type-block {
  cursor: pointer;
  background: #fff;
  padding: 16px;
  position: relative;
  border: 2px solid transparent;

  .pool-icon {
    width: 255px;
    height: 175px;
    position: absolute;
    left: 50%;
    margin-left: -127px;
    top: -57px;
  }

  &:hover {
    background: rgba(#F8B64C, 0.2);
    border-color: #F8B64C;
  }

  p {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 23px;
    margin-bottom: 10px;
    color: #4B4B4B;
  }
}
