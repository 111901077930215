.stats-public-view {
  padding-top: 50px;
  .player-survival-stats {
    max-height: calc(100vh - 200px);
  }
}

.player-survival-stats {
  position: relative;
  max-height: calc(100vh - 250px);
  overflow: hidden;

  .stats-header {
    padding: 12px 24px;
    border-radius: 5px 5px 0px 0px;
    background: #256E00;
    p {
      color: #FCFCFC;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .stats-body {
    overflow: auto;
    // max-height: calc(100% - 42px);

    // &.is-fixed {
    //   padding-left: 467px;
    // }

    .stats-colum {
      height: 100%;
      > div {
        color: #202020;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 700;
      }
      .row-item {
        padding: 8px 12px;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        line-height: 11px;
        min-width: 60px;
        @extend .d-flex;
        @extend .align-items-center;
        &.row-p-md {
          padding: 10px 12px;
        }
        &.row-l {
          min-width: 180px;
        }
        &.row-m {
          min-width: 72px;
        }
        &.is-win {
          color: #3A8216;
        }
        &.is-loss {
          color: #D72424;
          background: #FFF0F0;
        }
        &.id-draw {
          color: #D7BA24;
          background: #FFFDF0;
        }
        &.bg-win {
          background: #F3FFED !important;
          color: #3A8216;
          p {
            color: #202020;
          }
        }
        &.bg-loss {
          background: #FFF2F2 !important;
          color: #D72424;
          p {
            color: #202020;
          }
        }
        &.bg-current {
          background: #FFF5DA !important;
        }
      }
      .colum-header {
        background: #E2E2E2;
        height: 60px;
        p span {
          font-size: 22px;
          color: #3A8216;
          margin-right: 5px;
          margin-top: -2px;
        }
      }
      .colum-item {
        height: 44px;
        background: #F7F7F7;
        &:nth-child(2n) {
          background: #fff;
        }
        &.active {
          background: #FFEEC0;
        }
        span {
          font-size: 22px;
          color: #C73232;
          margin-right: 5px;
          line-height: 10px;
        }
      }
      &.has-right-border {
        border-right: 6px solid #256E00;
      }
      &.has-shadow {
        box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.18);
      }
      &.is-fixed {
        position: absolute;
        left: 0;
        z-index: 2;
        overflow: auto;
        height: calc(100% - 42px);
      }
    }

    .multi-select {
      height: 100%;
      .multi-select-pre__loading-indicator {
        display: none;
      }
      .multi-select-pre__control {
        height: 100%;
        background: transparent;
        border-radius: 0;
      }
      .multi-select-pre__placeholder {
        color: #202020 !important;
        font-size: 10px;
        font-weight: 700;
      }
      svg path {
        fill: #202020;
      }
      .multi-select-pre__indicator {
        margin-right: 10px;
      }
      .multi-select-pre__indicator-separator {
        display: none;
      }
      .multi-select-pre__menu {
        margin-top: 1px;
        border-radius: 0px;
      }
      .multi-select-pre__menu-list {
        padding-top: 0;
        .multi-select-pre__option {
          padding: 10px 16px;
          color: #202020;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          &:hover, &.multi-select-pre__option--is-focused, &.multi-select-pre__option--is-selected {
            background: #FEF0DB;
          }
        }
      }
      .multi-select-pre__control--menu-is-open {
        background: #fff;
        .multi-select-pre__placeholder {
          color: #FF9D00 !important;
        }
        svg path {
          fill: #FF9D00;
        }
      }
    }
  }
}

.filter-btns {
  border-radius: 4px;
  border: 1px solid #C2C2C2;
  margin-bottom: 16px;
  overflow: hidden;
  button {
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: #6E6E6E;
    border-right: 1px solid #C2C2C2;
    border-radius: 0;
    background: #fff;
    &.active {
      color: #FFF;
      font-weight: 600;
      background: #FF9D00;
    }
    &:nth-last-child(1) {
      border: none;
    }
  }
}
