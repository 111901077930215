input.input-custom[type='radio'],
input.input-custom[type='checkbox'] {
  & + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    color: #202020;
    font-weight: 400;
    height: 24px;
    width: 100%;
    line-height: 24px;
    margin-bottom: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
    }
  }
}

input.input-custom[type='radio'] + label {
  padding-left: 35px;

  &:before {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 4px solid #dbdbdb;
    background: #f7f7f7;
  }
}

.editing-checkbox-item {
  position: relative;
  input {
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index: 10;
    opacity: 0;
    & + label {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid #f7b64c;
      background: #fff;
      width: 24px;
      height: 24px;
    }
    &:checked + label {
      &:before {
        content: '\2713';
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }
}

// input.input-custom[type='checkbox'] + label {
//   padding-left: 28px;

//   &:before {
//     top: 0;
//     left: 0;
//     width: 24px;
//     height: 24px;
//     line-height: 25px;
//     border-radius: 5px;
//     font-size: 17px;
//     text-align: center;
//     color: #fff;
//     background-color: rgba(0, 0, 0, 0.4)
//   }
// }

input.input-custom[type='radio'] {
  opacity: 0;
  position: absolute;

  &:checked + label {
    font-size: 14px;
    color: #202020;
    font-weight: 400;
    opacity: 1;
    &:before {
      border: 4px solid #dbdbdb;
      background: #f7b64c;
    }
  }
}

// input.input-custom[type='checkbox'] {
//   // display: none;
//   opacity: 0;
//   position: absolute;

//   &:checked + label {
//     font-weight: 800;
//     color: #fff;
//     &:before {
//       content:'✔';
//     }
//   }
// }


.radiobox-item {
  cursor: pointer;

  label {
    font-size: 14px;
    font-weight: 400;
    color: #202020;
    cursor: pointer;
    margin: 0;
    margin-left: 10px;
  }
}
