.dash-page {
  background: #EFF4FB;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 20px;
}

.dash-tabs {
  margin-bottom: 25px;
  .dash-tab  {
    padding: 20px;
    width: 244px;
    height: 91px;
    margin-right: 30px;
    cursor: pointer;
    h4 {
      line-height: 18px;
    }
    span {
      font-size: 18px;
      line-height: 18px;
    }
    &.dash-orange {
      color: #FFB100;
      border: 2px solid #FFB100;
      background: #FFB1001A;
      &.active {
        box-shadow: 0px 6px 10px #00000029;
        background: #FFB100;
        color: #fff;
      }
    }
    &.dash-blue {
      color: #477EFF;
      border: 2px solid #477EFF;
      background: #477EFF1A;
      &.active {
        box-shadow: 0px 6px 10px #00000029;
        background: #477EFF;
        color: #fff;
      }
    }
    &.dash-green {
      color: #349A4C;
      border: 2px solid #349A4C;
      background: #349A4C1A;
      &.active {
        box-shadow: 0px 6px 10px #00000029;
        background: #349A4C;
        color: #fff;
      }
    }
  }
}

.dash-block {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 28px;
  margin-bottom: 25px;
  .block-title {
    font-size: 14px;
    color: #FF9D00;
    line-height: 18px;
    font-weight: bold;
    letter-spacing: 0.28px;
    .btn:focus {
      box-shadow: none;
    }
  }
  &.opened {

  }
  &.closed {
    height: 80px;
    overflow: hidden;
  }
}

.block-evaluation {
  .ratings-text {
    h3,h4 {
      font-size: 28px;
      color: #FF9D00;
      line-height: 37px;
    }
    img {
      margin-top: 85px;
      margin-bottom: 80px;
    }
    h4 {
      font-weight: normal;
    }
  }

  .doughnut-chart {
    width: 280px;
  }

  .res-text {
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    color: #FF9D00;
    margin-top: 22px;
  }

  .text-positive {
    color: #FF9D00;
    line-height: 25px;
    font-size: 29px;
    span {
      font-size: 19px;
    }
  }
}

.block-engaged, .block-attendance-ous, .block-attendance {
  .courses-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    span.circle {
      background: #ececec;
      min-width: 10px;
      min-height: 10px;
      border-radius: 100%;
      display: block;
    }

    li {
      margin-bottom: 18px;
      p {
        margin: 0 10px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.block-attendance-ous, .block-attendance {
  .courses-list {
    li {
      margin-bottom: 5px;
    }
  }
}
