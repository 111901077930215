.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-box {
  background: #fff;
  width: 625px;
  height: 460px;
  padding: 20px 30px;
  box-shadow: 0px 4px 59.8px 5.2px rgba(0, 12, 85, 0.14);
  position: relative;

  .header {
    height: 45px;
    border-bottom: 2px solid #d3d4d7;
    color: #242836;
    font-weight: bold;
    font-size: 18px;
  }

  .m-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .content {
    font-size: 15px;

    textarea {
      border-radius: 5px;
      resize: none;
      height: 120px;
      border: none;
      background: #eff4fb;
    }

    .warning-info {
      font-size: 14px;
      color: #242836;
      font-weight: 100;
    }
  }

  .footer {
    height: 40px;
    border-top: 2px solid #d3d4d7;
  }
}

.react-confirm-alert-overlay {
  z-index: 1000 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.react-confirm-alert {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 4px 0px 0px 4px;
  width: 530px;
}

.confirm-save {
  .modal-header h4 {
    color: #6E6E6E;
  }
  .modal-body {
    font-size: 18px;
    color: #6E6E6E;
  }
  .modal-footer {
    button {
      width: 100px;
      height: 35px;
      border-radius: 4px;
      padding: 5px;
      font-size: 14px;
      &.btn-gray {
        background: #EBEBEB;
      }
    }
  }
}

.change-password-modal {
  border-radius: 4px 0px 0px 4px;
  background: #F7F7F7;
  h3 {
    font-size: 24;
    color: #6E6E6E;
    font-weight: 500;
    margin-top: 20px;
  }

  .m-content {
    padding: 0 10px;
  }

  label {
    color: #6E6E6E;
  }
}

.calc-modal {
  width: 840px;
  min-height: 680px;
  padding: 34px 30px 18px 30px;
  background: #F7F7F7;
  overflow-y: auto;
  .total-info {
    font-size: 20px;
    color: #202020;
    font-weight: 600;
  }
  .m-close {
    right: 10px;
    top: 10px;
  }
  .input-blocks {
    width: 521px;
    .amount-toggle .first-section {
      min-width: 100px;
    }
    .block-title {
      min-width: 130px;
    }
    .amount-switcher .final-value {
      min-width: 70px;
      text-align: right;
    }
  }
  .calc-info-block {
    font-weight: 300;
    letter-spacing: 0.24px;
    width: 254px;
    .w-block {
      padding: 4px 12px;
      color: #202020;
    }

    .budget-calc {
      padding-top: 10px;
      padding-bottom: 15px;
      font-size: 12px;
      span {
        font-size: 10px;
        margin-left: 15px;
      }
    }
    div.mb-1 {
      margin-bottom: 3px !important;
    }
  }
  .w-block {
    background: #fff;
    margin-bottom: 5px;
    padding: 10px 20px;
  }
  .m-footer {
    button {
      min-width: 100px;
      height: 35px;
      border-radius: 4px;
      padding: 5px;
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
      &.btn-gray {
        background: #EBEBEB;
      }
    }
  }
}

.math-calc-form {
  width: 58px;
  .top-part {
    position: relative;
  }
  span {
    letter-spacing: 0.24px;
    margin-bottom: 2px;
    &.amount {
      font-size: 12px !important;
      &.green {
        color: #2BD138;
      }
      &.red {
        color: #FF3333;
      }
    }
  }
  .devide-action {
    font-size: 16px !important;
    position: absolute;
    left: -12px;
    top: 7px;
    &.action-21 {
      top: 30px;
    }
    &.action-13 {
      top: 51px;
    }
    &.action-23 {
      top: 74px;
    }
  }
  .result-line {
    height: 1px;
    background: #000;
    margin-top: 0;
    margin-bottom: 3px;
  }
}
